import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { validatePasswordConfirmation } from '@shared/validators/validators';
import { AuthenticationService } from '@auth/services/authentication.service';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangePasswordForm } from '@auth/models/auth.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonLoaderDirective } from '../../../../shared/directives/button-loader/button-loader.directive';
import { InvalidControlScrollDirective } from '../../../../shared/directives/invalid-control-scroll/invalid-control-scroll';
import { MatButtonModule } from '@angular/material/button';
import { ShowMatErrorDirective } from '../../../../shared/directives/show-mat-error/show-mat-error.directive';
import { ClearInputDirective } from '../../../../shared/directives/clear-input/clear-input.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ClearInputDirective,
    ShowMatErrorDirective,
    MatButtonModule,
    InvalidControlScrollDirective,
    ButtonLoaderDirective,
    RouterLink,
    MatIconModule,
    TranslateModule,
  ],
})
export class ChangePasswordComponent implements OnInit {
  environment = environment;
  form: ChangePasswordForm;
  loading = false;
  private formBuilder = inject(FormBuilder);
  private router = inject(Router);
  private authenticationService = inject(AuthenticationService);
  private _cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        oldPassword: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.minLength(8)]],
      },
      { validators: validatePasswordConfirmation },
    );
    this._cdr.markForCheck();
  }

  submit() {
    this.form.markAllAsTouched();
    this._cdr.markForCheck();
    if (!!this.form.valid) {
      this.loading = true;
      this._cdr.markForCheck();
      this.authenticationService
        .changePassword(this.form.value)
        .pipe(take(1))
        .subscribe((_) => {
          this.loading = false;
          this._cdr.markForCheck();
          this.router.navigate(['']);
        });
    }
  }
}
