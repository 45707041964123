import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, GuardsCheckEnd, Router } from '@angular/router';
import { RETURN_URL_PARAM } from '@auth/guards/authentication.guard';
import { LoginForm } from '@auth/models/auth.model';
import { LoginService } from '@auth/services/login.service';
import { SnackBarService } from '@core/services/snackbar/snack-bar.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { EMPTY, catchError, debounceTime, filter, finalize, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ButtonLoaderDirective } from '../../../../shared/directives/button-loader/button-loader.directive';
import { InvalidControlScrollDirective } from '../../../../shared/directives/invalid-control-scroll/invalid-control-scroll';
import { MatButtonModule } from '@angular/material/button';
import { ShowMatErrorDirective } from '../../../../shared/directives/show-mat-error/show-mat-error.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { Store } from '@ngxs/store';
import { Set2FAToken } from '../../store/auth.actions';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ClearInputDirective } from '@app/shared/directives/clear-input/clear-input.directive';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InlineSVGModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ShowMatErrorDirective,
    MatButtonModule,
    InvalidControlScrollDirective,
    ButtonLoaderDirective,
    ClearInputDirective,
  ],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: 0,
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
          overflow: 'hidden',
          opacity: 1,
        }),
      ),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  isExpanded = false;
  form: LoginForm;
  returnUrl: string;
  environment = environment;
  loading = false;
  private destroyRef = inject(DestroyRef);
  private store = inject(Store);

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    private translateService: TranslateService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this._cdr.markForCheck();
    // router.navigate only permits known routes, so injecting another domain in the queryParam is not possible
    const desiredReturnUrl = this.route.snapshot.queryParams[RETURN_URL_PARAM];
    this.returnUrl = desiredReturnUrl || '/';
  }

  activateTokenFormControl(detail) {
    this.form.get('email').disable();
    this.form.get('password').disable();
    const tokenFormControl = (this.form as FormGroup)?.get('token');
    if (!tokenFormControl) {
      (this.form as FormGroup).addControl('token', new FormControl(''));
      (this.form as FormGroup)
        .get('token')
        .valueChanges.pipe(
          takeUntilDestroyed(this.destroyRef),
          debounceTime(500),
          filter((token) => token.length == 6),
        )
        .subscribe(() => this.login());
    } else {
      tokenFormControl.setErrors({ incorrect: true });
      this.snackBar.info(detail);
    }
    this.isExpanded = true;

    this._cdr.markForCheck();
  }

  login() {
    if (this.form.valid) {
      this.loading = true;
      this._cdr.markForCheck();
      const email = this.form.get('email').value;
      const password = this.form.get('password').value;
      const token = this.form.get('token')?.value;

      this.router.events
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter((event) => event instanceof GuardsCheckEnd),
          take(1),
        )
        .subscribe((event: GuardsCheckEnd) => !event.shouldActivate && this.router.navigate(['/']));

      const payload: any = { email, password };
      token && (payload.otpToken = token);

      if (email && password) {
        this.loginService
          .login(payload)
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            catchError((error) => {
              if (error.error?.code == 'mfa_setup_required') {
                this.snackBar.info(error.error.detail);
                this.store.dispatch(new Set2FAToken(error.error?.['mfaAccessToken']));
                this.router.navigate(['login/2fa-setup']);
              } else if (error.error?.code == 'invalid_otp_token') {
                this.activateTokenFormControl(error.error.detail);
              } else {
                (error.status === 400 || error.status === 401) &&
                  this.snackBar.warn(this.translateService.instant('loginScreen.errors.unknownUsernameOrPassword'));
              }
              return EMPTY;
            }),
            finalize(() => {
              this.loading = false;
              this._cdr.markForCheck();
            }),
          )
          .subscribe(() => this.router.navigate([this.returnUrl]));
      }
    }
  }
}
