import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackBarService } from '@core/services/snackbar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackBar: SnackBarService,
    private translateService: TranslateService,
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const sentryException = err.error || err.message || err;

        if (err.status >= 500) {
          this.snackBar.warn(err?.error?.detail || this.translateService.instant('general.errors.unknownServerError'));
        } else if (
          err.status === 400 &&
          ((!!err?.error &&
          Array.isArray(err?.error) &&
          !!err?.error?.find((e) => e?.toString()?.toLowerCase().includes('overlapping invoices'))) || err.url.includes('attach-asset'))
        ) {
          // skip for overlapping invoices or for attach assets error messages
        } else if (err.status === 403) {
          this.snackBar.warn(this.translateService.instant('general.errors.permissionError'));
        } else if (err.status !== 401 && !(err.url?.includes('assets-server') && err.status == 400)) {
          Sentry.captureException(sentryException);
          this.snackBar.warn(err?.error?.detail || this.translateService.instant('general.errors.unknownClientError'));
        } else if ((err?.error?.detail || err?.error?.email) && !err?.error?.detail?.toLowerCase().includes('token')) {
          // General erorrs TODO this should become erorr mapper with full FE-BE integration
          Sentry.captureException(sentryException);
          this.snackBar.warn(
            this.translateService.instant('general.errors.generalError', {
              error: Object.values(err?.error).toString(),
            }),
          );
        }
        return throwError(() => err);
      }),
    );
  }
}
