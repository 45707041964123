import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
// import { HoverPreloadModule } from 'ngx-hover-preload-v2';
import { MaterialModule } from './modules/material.module';
import { SkeletonLoaderModule } from './modules/skeleton-loader/skeleton-loader.module';
@Injectable({
  providedIn: 'root'
})
class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

const modules: any[] = [
  CommonModule,
  TranslateModule,
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  SkeletonLoaderModule,
  InlineSVGModule.forRoot(),
  HammerModule
];

@NgModule({
    imports: modules,
    exports: modules,
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        }
    ]
})
export class SharedModule {}
