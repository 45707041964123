import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';

// modules
const modules: any[] = [
  SharedModule,
  NgProgressModule,
  NgProgressHttpModule.withConfig({
    silentApis: ['https://www.google-analytics.com']
  }),
  NotificationItemComponent,
  NotificationListComponent
];

@NgModule({
    imports: modules,
    exports: modules
})
export class CoreModule {}
